export const defaultSlugLocales = {
  no: {
    _type: "slug",
    current: "",
  },
  en: {
    _type: "slug",
    current: "",
  },
  se: {
    _type: "slug",
    current: "",
  },
  da: {
    _type: "slug",
    current: "",
  },
  fi: {
    _type: "slug",
    current: "",
  },
  de: {
    _type: "slug",
    current: "",
  },
  fr: {
    _type: "slug",
    current: "",
  },
  es: {
    _type: "slug",
    current: "",
  },
};

export const defaultCategoryLocales = {
  no: {
    _type: "slug",
    current: "",
  },
  en: {
    _type: "slug",
    current: "",
  },
  se: {
    _type: "slug",
    current: "",
  },
  da: {
    _type: "slug",
    current: "",
  },
  fi: {
    _type: "slug",
    current: "",
  },
  de: {
    _type: "slug",
    current: "",
  },
  fr: {
    _type: "slug",
    current: "",
  },
  es: {
    _type: "slug",
    current: "",
  },
};

import appConfigRaw from "../app.config.json";

// Validate locales
if (!appConfigRaw.locales) {
  throw new Error("app.config.js: locales is not defined");
}
if (appConfigRaw.locales.filter((l) => l.isDefault).length > 1) {
  throw new Error("app.config.js: cannot have more than one default locale");
}

const appConfig = {
  ...appConfigRaw,
  locales: appConfigRaw.locales.map((locale) => ({
    ...locale,
    urlPrefix: locale.urlPrefix.replace(/\//g, ""),
  })),
};

export const enAndNoLocales = appConfig.locales.filter((locale) =>
  ["en", "no"].includes(locale.urlPrefix)
);

export const locales = appConfig.locales;

export const siteSpecificLocales = (siteName) =>
  siteName === "lildog" ? appConfig.locales : enAndNoLocales;

export const defaultLocale = appConfig.locales.find((l) => l.isDefault);

export const countries = appConfig.countries;

export const defaultCountry = appConfig.countries.find((l) => l.isDefault);

export const isMultilingual = appConfig.locales.length > 1;

export default appConfig;
