import { useContext, useState, useEffect } from "react";
import Image from "next/image";
import { CartContext, CountryContext } from "../../context";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { CountryContextType } from "../../context/types";
import { TruckIcon } from "@heroicons/react/24/outline";
import { ReceiptRefundIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import FlagIcon from "../FlagIcon";
import {
  getPositionFromHotspot,
  GlobalConfig,
  Image as ImageType,
  LildogProductPrices,
  LocaleType,
  Product,
} from "../../lib";
import Vippsbutton from "../Vippsbutton";
import appConfig from "../../lib/app-config";
import Lildogswitcher from "../lildogswitcher";
import { useLocale } from "../../translations";
import AddToCart from "../Button/addToCartBtn";
import { Disclosure, Transition, Tab } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import ReviewStars from "../reviewStars";
import { Fragment } from "react";
import PricingTable from "../pricingTable";
import MuxPlayer from "@mux/mux-player-react";
import CountryAvailabilityModal from "../CountryAvailabilityModal";
import KlarnaLogo from "../svgs/klarnaLogo";
import Vippslogocard from "../svgs/vippslogocard";
import AmericanExpressLogo from "../svgs/AmericanExpressLogo";
import Mastercard from "../svgs/Mastercard";
import VisaLogo from "../svgs/VisaLogo";
import {
  getCountryByCountryCode,
  validCountryCodes,
} from "../../lib/countryNameAndCode";
import router from "next/router";
import {
  loadStripe,
  StripeElementLocale,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import FastCheckout from "../fastCheckout";
import PaypalLogo from "../svgs/paypalLogo";
import { localizedExternalPurchaseUrls } from "../NavBar";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  images: Array<
    ImageType & {
      _key: string;
      shouldShowBg: boolean;
      alt: string;
    }
  >;
  logo: ImageType;
  availableCountriesPopup: {
    availableCountriesText: string;
    validCountriesImage: ImageType;
  };
  reviewText: string;
  body: Array<any>;
  productInfo: Array<string>;
  rating: number;
  worksInText: string;
  countryList: Array<{ country: string; _key: string }>;
  buyBtnText: string;
  textUnderButton: string;
  paymentIcons: ImageType;
  reviews: any;
  icons: Array<{ text: string; image: ImageType; _key: string; _type: string }>;
  technicalData: {
    header: string;
    tableTitle: string;
    specifications: Array<{
      leftColumn: string;
      rightColumn: any;
      _key: string;
    }>;
    bigImag: ImageType;
    images: Array<ImageType & { text: string; _key: string }>;
    icons: Array<{ text: string; image: ImageType; _key: string }>;
  };
  bottomText: string;
  mainProduct: Product;
  mainProductPrices: LildogProductPrices;
  locale: LocaleType;
  productTaxRate: number;
  products: Product[];
  siteName: "lildog" | "lilcat";
  urlFor: Function;
  SimpleBlockContent: any;
  config: GlobalConfig;
}
const LocalizedNewImageAndProductInfo = (props: Props) => {
  const {
    images,
    logo,
    textUnderButton,
    bottomText,
    urlFor,
    mainProductPrices,
    availableCountriesPopup,
    SimpleBlockContent,
    body,
    productInfo,
    mainProduct,
    buyBtnText,
    productTaxRate,
    technicalData,
    reviews,
    siteName,
    config,
  } = props;

  const countryContext = useContext(CountryContext);
  const { nrOfMainProducts } = useContext(CartContext);
  const { country } = countryContext;
  const urlPrefix = useLocale().urlPrefix;
  const [showAvailableCountryModal, setShowAvailableCountryModal] =
    useState(false);
  const [strippedProduct, setStrippedProduct] = useState<any>([]);
  const discount = 0;
  const [isAgriaPage, setIsAgriaPage] = useState(false);
  const [agriaCoupon, setAgriaCoupon] = useState("");

  useEffect(() => {
    if (window !== undefined) {
      setIsAgriaPage(window.location.href.includes("agria"));
    }
  }, []);

  const fetchCouponData = async (couponCode: string) => {
    try {
      const response = await axios.post("/api/payment/get_coupon_value", {
        couponCode: couponCode,
      });
      setAgriaCoupon(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAgriaPage) {
      fetchCouponData("lildog50");
    }
  }, [isAgriaPage]);

  useEffect(() => {
    setStrippedProduct({
      id: mainProduct.id,
      tax_class: mainProduct.tax_class,
      tax_status: mainProduct.tax_status,
      quantity: nrOfMainProducts,
      image: mainProduct?.images[0].src,
      euro_price: mainProduct.meta_data.filter(
        (price) => price.key === "_euro_price"
      )[0].value,
      usd_price: mainProduct.meta_data.filter(
        (price) => price.key === "_usa_price"
      )[0].value,
      sek_price: mainProduct.meta_data.filter(
        (price) => price.key === "_sweden_price"
      )[0].value,
      dkk_price: mainProduct.meta_data.filter(
        (price) => price.key === "_denmark_price"
      )[0].value,
      nok_price: mainProduct.price,
      name: mainProduct.name,
    });
  }, [nrOfMainProducts, mainProduct]);

  const worksInCountry = (countryCode: string) => {
    const country = getCountryByCountryCode(countryCode);
    if (country.length === 0) {
      return "";
    }
    if (validCountryCodes.includes(country[1])) {
      return `${translations.worksIn[urlPrefix]} ${country[0]}.`;
    } else {
      return `${translations.doesNotWorkIn[urlPrefix]} ${country[0]}.`;
    }
  };
  const getTotalPrice = () => {
    let price: number = 0;
    if (strippedProduct && discount) {
      // Return (price of product minus price of coupons) + tax * (price of product minus price of coupons) in right currency
      price = 799; // #TODO: Change this to the price of the product
    } else if (strippedProduct) {
      //Return price of products * tax
      price =
        parseFloat(mainProductPrices?.PRICE?.[country?.currency]) *
          nrOfMainProducts +
        (parseFloat(mainProductPrices?.PRICE?.[country?.currency]) *
          productTaxRate *
          nrOfMainProducts) /
          100;
    }
    return price.toFixed(2);
  };

  const discountPercent =
    (parseFloat(
      mainProductPrices?.ORIGINAL_PRICE?.[props?.locale?.defaultCurrency] ??
        null
    ) - parseFloat(mainProductPrices?.PRICE?.[props?.locale.defaultCurrency]) ??
      null) /
    parseFloat(
      mainProductPrices?.ORIGINAL_PRICE?.[props?.locale.defaultCurrency] ?? null
    );

  const BlackFridaySaleComp = () => {
    return (
      <div className="z-20 absolute bg-black  right-0 w-fit h-fit">
        {isNaN(discountPercent) === false ? (
          <h1 className="text-center text-white font-semibold text-base mx-4 my-1">
            BLACK FRIDAY: {(discountPercent * 100).toFixed(0)}%{" "}
            {translations.blackFridayDiscount[urlPrefix]}
          </h1>
        ) : (
          <h1 className="text-center text-white font-semibold text-base mx-4 my-1">
            BLACK FRIDAY
          </h1>
        )}
      </div>
    );
  };

  const AgriaPriceComponent = () => {
    {
      /* Agria is only on the Norweigan site, therfor only support for NOK for NOW */
    }
    return (
      <div>
        {agriaCoupon && (
          <div className="z-20 flex absolute bg-primary-1 rounded-lg  right-0 w-fit h-fit">
            <h1 className="text-center justify-center text-primary-4 font-p22 text-base mx-4 my-1">
              {mainProductPrices?.PRICE && (
                <p>
                  Agria-pris: Kr{" "}
                  {Math.ceil(
                    (parseFloat(mainProductPrices?.PRICE.NOK) -
                      parseFloat(agriaCoupon)) *
                      1.25
                  ) * nrOfMainProducts}
                </p>
              )}
            </h1>
          </div>
        )}
      </div>
    );
  };

  const shouldRedirect = Boolean(
    localizedExternalPurchaseUrls[siteName]?.[urlPrefix]
  );

  return (
    <div
      id="purchase-section"
      className="bg-white py-4 sm:py-8 font-objektiv max-w-[1250px] text-primary-mono-1 mx-auto"
    >
      <CountryAvailabilityModal
        textContent={availableCountriesPopup?.availableCountriesText}
        backgroundImgUrl={urlFor(
          availableCountriesPopup?.validCountriesImage
        ).url()}
        show={showAvailableCountryModal}
        onClose={() => setShowAvailableCountryModal(false)}
      />
      <div className="max-w-2xl mx-auto lg:max-w-none">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse pt-12 px-2 ">
            {/* Image selector */}
            <Tab.List
              className={`grid grid-cols-4 columns-md gap-4 mt-6 lg:gap-12`}
            >
              {images?.map((image, i) => (
                <Tab
                  key={image._key}
                  className="relative cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-0 sm:focus:ring-offset-4 focus:ring-opacity-50"
                  aria-label={`next-image-button-${i}`}
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={`relative rounded-md overflow-hidden h-16 sm:h-24 
                        ${image.shouldShowBg && "bg-primary-mono-3"}
                        `}
                      >
                        {image._type === "image" && (
                          <Image
                            src={urlFor(image, true).url()}
                            alt={image.alt ? image.alt : ""}
                            layout="fill"
                            objectFit="cover"
                            objectPosition={
                              image?.hotspot &&
                              getPositionFromHotspot(image.hotspot)
                            }
                          />
                        )}
                        {image._type === "videoPost" && (
                          <div>
                            <Image
                              src={`https://image.mux.com/${image.asset.playbackId}/thumbnail.png?time=8`}
                              layout="fill"
                              objectFit="cover"
                            />
                            <div className="flex justify-center items-center absolute inset-0 ">
                              <BsFillPlayCircleFill className="text-white w-8 h-8 md:w-10 md:h-10" />
                            </div>
                          </div>
                        )}
                      </div>
                      <span
                        className={classNames(
                          selected ? "ring-primary-mono-2" : "ring-transparent",
                          "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="w-full mx-auto sm:w-4/5 lg:w-full ">
              {images?.map((image) => (
                <Tab.Panel key={image._key}>
                  {image._type === "image" && (
                    <div
                      className={`relative w-full ${
                        image.shouldShowBg && "bg-primary-mono-3"
                      } rounded-xl rounded-bl-none`}
                      style={{ aspectRatio: "16 / 9" }}
                    >
                      <AgriaPriceComponent />
                      <Image
                        src={urlFor(image, true).url()}
                        alt=""
                        priority
                        layout="fill"
                        objectFit="cover"
                        className="rounded-xl rounded-bl-none"
                        objectPosition={
                          image?.hotspot &&
                          getPositionFromHotspot(image.hotspot)
                        }
                      />
                    </div>
                  )}
                  {image._type === "videoPost" && (
                    <div
                      className="relative w-full rounded-xl rounded-bl-none  overflow-hidden "
                      style={{ aspectRatio: "16 / 9" }}
                    >
                      <MuxPlayer
                        playbackId={image.asset.playbackId}
                        metadataVideoTitle="Placeholder (optional)"
                        metadataViewerUserId="Placeholder (optional)"
                        primaryColor="#FFFFFF"
                        secondaryColor="#000000"
                        autoPlay={false}
                        style={{
                          aspectRatio: "16 / 9",
                        }}
                      />
                    </div>
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
          {/* Product info */}
          <div
            className="px-4 self-center mt-4 sm:mt-6 lg:mt-0 lg:pl-8 xl:px-0"
            id="scroll-to-purchase-section"
          >
            <div className="relative w-full flex justify-between translate-y-10">
              <div className="relative w-[120px] h-[45px]">
                <Image
                  src={urlFor(logo, true).url()}
                  layout="fill"
                  objectFit="contain"
                  priority={true}
                  alt={logo.alt ? logo.alt : ""}
                />
              </div>
              <div className="mt-3 pb-6">
                <h3 className="sr-only">Product information</h3>
                <span className="flex space-x-4 pr-8">
                  {mainProductPrices?.ORIGINAL_PRICE && (
                    <span className="line-through text-2xl">
                      {country.currency === "NOK" ||
                      countryContext.country.currency === "SEK" ||
                      countryContext.country.currency === "DKK"
                        ? " kr"
                        : country.currency}{" "}
                      {Math.ceil(
                        parseInt(
                          mainProductPrices?.ORIGINAL_PRICE[country.currency]
                        ) +
                          (mainProductPrices?.ORIGINAL_PRICE[country.currency] *
                            productTaxRate) /
                            100
                      )}
                    </span>
                  )}
                  <p className="text-2xl text-primary-1 whitespace-nowrap">
                    {countryContext.country.currency === "NOK" ||
                    countryContext.country.currency === "SEK" ||
                    countryContext.country.currency === "DKK"
                      ? " kr"
                      : countryContext.country.currency}{" "}
                    {productTaxRate
                      ? (parseFloat(
                          mainProductPrices?.PRICE[
                            countryContext.country.currency
                          ]
                        ) +
                          (mainProductPrices?.PRICE[
                            countryContext.country.currency
                          ] *
                            productTaxRate) /
                            100) *
                        nrOfMainProducts
                      : (parseFloat(
                          mainProductPrices?.PRICE[
                            countryContext.country.currency
                          ]
                        ) +
                          (mainProductPrices?.PRICE[
                            countryContext.country.currency
                          ] *
                            25) /
                            100) *
                        nrOfMainProducts}{" "}
                    {appConfig.currencySymbols[countryContext.country.currency]}
                  </p>
                </span>
              </div>
            </div>
            <div className="mt-6">
              <h3 className="sr-only">Description</h3>
              {reviews && (
                <div className="flex flex-col justify-center">
                  <ReviewStars rating={reviews?.totalAverage} />
                </div>
              )}
              <div className="text-base text-primary-mono-1 space-y-6">
                <SimpleBlockContent blocks={body} />
              </div>
              {productInfo && (
                <div className="space-y-2 my-4">
                  {productInfo.map((info, index) => {
                    //CheckIcon
                    return (
                      <div className="flex items-center" key={index}>
                        <div className="h-6 w-6">
                          <CheckIcon
                            className="text-primary-mono-3 h-full w-full font-bold"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="pl-1 w-full">{info}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {bottomText && (
              <Disclosure as="div" className="">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`
                          ${open ? "" : "text-opacity-90"}
                          group inline-flex items-center rounded-md px-3 py-2 text-base font-semibold text-primary-mono-1 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span className="text-primary-mono-2">{bottomText}</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "-rotate-180" : "rotate-0 text-opacity-70"
                        }
                            ml-2 h-5 w-5 text-primary-mono-1 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      {technicalData && (
                        <Disclosure.Panel
                          className="text-primary-mono-2
                            mt-3 w-full -translate-x-0 transform"
                        >
                          <div>
                            <div className="col-span-3">
                              {technicalData?.header && logo && (
                                <div className="bg-primary-mono-4 w-full py-4 px-2 sm:px-8">
                                  <div className="relative w-[120px] h-[45px]">
                                    <Image
                                      src={urlFor(logo, true).url()}
                                      layout="fill"
                                      objectFit="contain"
                                      priority={true}
                                      alt={logo.alt ? logo.alt : ""}
                                    />
                                  </div>
                                  <h3 className="text-base font-semibold leading-7 lg:w-1/2">
                                    {technicalData?.header || ""}
                                  </h3>
                                </div>
                              )}
                              <div className="mt-6 border-primary-mono-3">
                                <dl className="divide-y divide-primary-mono-3">
                                  {technicalData?.specifications?.map((row) => {
                                    return (
                                      <div
                                        key={row._key}
                                        className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                                      >
                                        <dt className="text-sm font-bold leading-6 ">
                                          {row.leftColumn}
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6  sm:col-span-2 sm:mt-0">
                                          <SimpleBlockContent
                                            blocks={row.rightColumn}
                                          />
                                        </dd>
                                      </div>
                                    );
                                  })}
                                </dl>
                              </div>
                            </div>
                            <div className="col-span-2 flex flex-row"></div>
                          </div>
                          <div className="relative flex flex-row justify-between bg-primary-mono-4 m-4 rounded-xl">
                            {technicalData.icons?.map((item) => {
                              const { text, image, _key } = item;
                              return (
                                <div key={_key} className="p-2">
                                  <div className="flex sm:hidden justify-center">
                                    <Image
                                      src={urlFor(image, true).url()}
                                      height={30}
                                      width={30}
                                      alt={text || ""}
                                    />
                                  </div>
                                  <div className="hidden sm:flex md:justify-center">
                                    <Image
                                      src={urlFor(image, true).url()}
                                      height={40}
                                      width={40}
                                      alt={text || ""}
                                    />
                                  </div>
                                  <div className="text-xs text-center sm:text-sm">
                                    {text}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Disclosure.Panel>
                      )}
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            <div className="mt-6 mb-4">
              <div className="flex space-y-0 sm:space-x-4">
                <div className="grow">
                  <AddToCart
                    config={config}
                    mainProduct={mainProduct}
                    buyBtnText={buyBtnText}
                    borderWidth={0}
                    siteName={siteName}
                  />
                </div>
                <div className="hidden sm:flex">
                  <Lildogswitcher />
                </div>
              </div>
              {!shouldRedirect && (
                <div className="flex flex-col space-y-2 pt-2">
                  {urlPrefix === "no" && (
                    <div
                      className={`${siteName == "lilcat" ? "hidden" : "grow"}`}
                    >
                      <Vippsbutton
                        productID={mainProduct.id}
                        coupon=""
                        lgScreenWidth="lg:w-1/2"
                        smScreenWidth="sm:w-full"
                        mainProduct={mainProduct}
                        input={{
                          acceptTerms: true,
                          subscribeNews: false,
                        }}
                        getTotalPrice={getTotalPrice}
                      />
                    </div>
                  )}
                  <FastCheckout
                    getTotalPrice={getTotalPrice}
                    product={mainProduct}
                    productTaxRate={productTaxRate}
                    country={country}
                    nrMainProducts={nrOfMainProducts}
                    klarna={false}
                  />
                </div>
              )}
              {urlPrefix === "no" ? (
                <div className="grid grid-cols grid-cols-6 gap-4 items-center w-fit max-w-full mt-6 mx-2 ">
                  <KlarnaLogo width={40} height={29} />
                  <Vippslogocard width={40} height={29} />
                  <VisaLogo width={40} height={29} />
                  <Mastercard width={40} height={29} />
                  <AmericanExpressLogo width={40} height={29} />
                  <PaypalLogo width={40} height={29} />
                </div>
              ) : (
                <div className="grid grid-cols grid-cols-5 gap-4 items-center w-fit max-w-full mt-6 mx-2 ">
                  <KlarnaLogo width={40} height={29} />
                  <VisaLogo width={40} height={29} />
                  <Mastercard width={40} height={29} />
                  <AmericanExpressLogo width={40} height={29} />
                  <PaypalLogo width={40} height={29} />
                </div>
              )}
              <div className="grid grid-cols-8 items-center mt-4 min-w-fit max-w-full md:w-3/5 ml-2 sm:ml-4 space-y-2 mr-1">
                <div className="w-5 h-5 bg-green-600 rounded-full ml-0.5" />
                <p className="col-span-7">
                  {translations.available[urlPrefix]}
                </p>
                <div className="w-6 h-6">
                  <FlagIcon countryCode="no" />
                </div>
                <div className="col-span-7 flex flex-col sm:flex-row sm:space-x-2">
                  <p className="w-fit">{worksInCountry(country.countryCode)}</p>
                  <p
                    className="w-fit text-purple-600 hover:underline hover:cursor-pointer"
                    onClick={() => setShowAvailableCountryModal(true)}
                  >
                    {translations.checkOtherCountries[urlPrefix]}
                  </p>
                </div>
                <div className="w-6 h-6">
                  <TruckIcon />
                </div>
                <p className="col-span-7">
                  {translations.freeShipping[urlPrefix]}
                </p>
              </div>
              <Disclosure as="div" className="m-1">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex items-center justify-start text-left text-black space-x-2">
                        <span className="flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-4 w-4 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                        <span className="font-light text-[13px] leading-[37px] text-primary-mono-1">
                          {textUnderButton}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd">
                      <div className="mb-6 flex flex-col space-y-4 px-2 font-objektiv">
                        <PricingTable />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading-2" className="sr-only">
                Additional details
              </h2>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocalizedNewImageAndProductInfo;

const translations = {
  available: {
    en: "Available",
    no: "Tilgjengelig",
    se: "Tillgänglig",
    da: "Tilgængelig",
    fi: "Saatavilla",
    de: "Verfügbar",
    fr: "Disponible",
    es: "Disponible",
  },
  worksIn: {
    en: "Works in",
    no: "Fungerer i",
    se: "Fungerar i",
    da: "Fungerer i",
    fi: "Toimii",
    de: "Funktioniert in",
    fr: "Fonctionne en",
    es: "Funciona en",
  },
  doesNotWorkIn: {
    en: "Does not work in",
    no: "Fungerer ikke i",
    se: "Fungerar inte i",
    da: "Fungerer ikke i",
    fi: "Ei toimi",
    de: "Funktioniert nicht in",
    fr: "Ne fonctionne pas en",
    es: "No funciona en",
  },
  checkOtherCountries: {
    en: "Check other countries",
    no: "Sjekk andre land",
    se: "Kolla andra länder",
    da: "Tjek andre lande",
    fi: "Tarkista muut maat",
    de: "Überprüfen Sie andere Länder",
    fr: "Vérifiez les autres pays",
    es: "Verifique otros países",
  },
  freeShipping: {
    en: "Same day free shipping",
    no: "Gratis frakt",
    se: "Gratis frakt",
    da: "Gratis fragt",
    fi: "Ilmainen toimitus",
    de: "Kostenloser Versand",
    fr: "Livraison gratuite",
    es: "Envío gratis",
  },
  freeTrial: {
    en: "30-Day free trial",
    no: "30 dagers gratis prøveperiode",
    se: "30 dagars gratis provperiod",
    da: "30 dages gratis prøveperiode",
    fi: "30 päivän ilmainen kokeilu",
    de: "30 Tage kostenlose Testversion",
    fr: "Essai gratuit de 30 jours",
    es: "Prueba gratuita de 30 días",
  },
  blackFridayDiscount: {
    en: "DISCOUNT",
    no: "RABATT",
    se: "RABATT",
    da: "RABAT",
    fi: "ALE",
    de: "RABATT",
    fr: "RABAIS",
    es: "DESCUENTO",
  },
};
