import Image from "next/image";
import { GlobalConfig, Image as ImageType } from "../../lib/types";
import { Product } from "../../lib";
import { getPositionFromHotspot } from "../../lib/helpers";
import { useRouter } from "next/router";
import ScrollButton from "../Button/scrollbutton";
import { Carticon } from "../svgs";
import ReviewStars from "../reviewStars";
import Link from "next/link";
import { useLocale } from "../../translations";
import { CountryContext } from "../../context";
import { useContext } from "react";
import { localizedExternalPurchaseUrls } from "../NavBar";
interface Props {
  boldText: string;
  smallText: string;
  buttonText: string;
  reviewsText: string;
  blackFridaySale: string;
  icons: Array<{ text: string; image: ImageType; _key: string; _type: string }>;
  quote: string;
  authorOfQuote: string;
  mainProduct: Product;
  mainImage: ImageType;
  appImage: ImageType;
  urlFor: Function;
  reviews: any;
  siteName: "lildog" | "lilcat";
  config: GlobalConfig;
}

const LocalizedImageAndButton = (props: Props) => {
  const {
    boldText,
    smallText,
    buttonText,
    reviewsText,
    icons,
    quote,
    authorOfQuote,
    mainImage,
    appImage,
    urlFor,
    reviews,
    siteName,
    blackFridaySale,
    config,
  } = props;
  const router = useRouter();
  const isLandingPageB = router.query?.utm_lp === "lp2";
  const locale = useLocale().urlPrefix;
  const redirectUrl = localizedExternalPurchaseUrls[siteName]?.[locale];
  const shouldRedirect = Boolean(redirectUrl);

  return (
    <div
      className={`w-full flex flex-col justify-center ${
        isLandingPageB && "mb-8 sm:mb-16"
      }`}
    >
      <div className="relative w-full flex items-center justify-center rounded-3xl h-[650px]">
        <Image
          src={urlFor(mainImage, true).quality(30).url()}
          layout="fill"
          objectFit="cover"
          priority={true}
          alt={mainImage?.alt || ""}
          objectPosition={
            mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
          }
        />
        <div className="absolute top-0 left-0 w-full h-full bg-green-900 opacity-10 z-10"></div>
        <div className="absolute w-full h-[650px] " />
        {appImage && (
          <div className="absolute hidden lg:flex h-[540px] w-[370px] lg:h-[600px] lg:w-[400px] md:right-8 lg:right-12 xl:right-18 2xl:right-24  top-6 z-30">
            <Image
              src={urlFor(appImage, true).quality(30).url()}
              layout="fill"
              objectFit="cover"
              priority={true}
              alt={appImage?.alt || ""}
              objectPosition={
                appImage?.hotspot && getPositionFromHotspot(appImage.hotspot)
              }
            />
          </div>
        )}
        <div className="flex justify-start relative max-w-6xl w-full px-4 xl:px-0 z-20">
          <div className="flex flex-col w-full items-center lg:items-start">
            <div className="items-center flex flex-col justify-center translate-y-10 ">
              <h2 className="text-white font-objektiv font-normal text-center text-2xl mb-6">
                {blackFridaySale || ""}
              </h2>
              <h1 className="text-white font-p22 font-semibold text-center leading-10 text-3xl max-w-lg">
                {boldText || ""}
              </h1>
              <h2 className="text-white font-objektiv font-normal text-center text-lg">
                {smallText || ""}
              </h2>
              <div className="mt-14 md:mt-8 mx-auto">
                {shouldRedirect ? (
                  <a href={redirectUrl}>
                    <span className="flex bg-primary-4 justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow rounded-xl h-[48px] w-full min-w-[300px] lg:mx-0 focus:outline-none focus:ring-2">
                      <span className="flex items-center justify-center space-x-2 font-bold px-2 w-full md:px-4">
                        <p className="text-primary-2">{buttonText}</p>
                        <Carticon
                          height={23}
                          width={21}
                          color={siteName === "lildog" ? "#094E18" : "#ebffc7"}
                        />
                      </span>
                    </span>
                  </a>
                ) : (
                  <Link
                    href={{
                      pathname: "/[lang]/[category]",
                    }}
                    as={`/${locale}/${
                      config?.checkoutSlug?.current || "checkout"
                    }`}
                  >
                    <span className="flex bg-primary-4 justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow rounded-xl h-[48px] w-full min-w-[300px] lg:mx-0 focus:outline-none focus:ring-2">
                      <span className="flex items-center justify-center space-x-2 font-bold px-2 w-full md:px-4">
                        <p className="text-primary-2">{buttonText}</p>
                        <Carticon
                          height={23}
                          width={21}
                          color={siteName === "lildog" ? "#094E18" : "#ebffc7"}
                        />
                      </span>
                    </span>
                  </Link>
                )}
              </div>
              {reviews && (
                <div className="flex flex-col justify-center">
                  <ReviewStars
                    rating={reviews?.totalAverage}
                    textColor="text-white"
                  />
                  <div className="text-white font-p22 pb-2 text-[19px] leading-[24px] text-center font-objektiv">
                    {reviewsText}
                  </div>
                </div>
              )}
              <div className=" hidden  items-center justify-evenly mt-6 space-x-12">
                {icons?.map((item) => {
                  const { text, image, _key } = item;
                  return (
                    <div key={_key}>
                      <div className="relative">
                        <Image
                          src={urlFor(image, true).url()}
                          height={45}
                          width={65}
                          alt={image.alt || ""}
                        />
                      </div>
                      {text && (
                        <p className="text-white pb-2 text-[15px] leading-[24px] text-center font-objektiv">
                          {text}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="hidden  my-4">
                <p className="text-white font-objektiv font-medium text-center text-base px-6 lg:px-0">
                  {quote || ""}
                </p>
                <p className="text-white font-objektiv font-normal text-center text-sm px-6 lg:px-0">
                  {authorOfQuote || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedImageAndButton;
