import Image from "next/image";
import { Image as ImageProps, SectionProps } from "../../lib";

interface Props extends SectionProps {
  header: string;
  logos: Array<ImageProps & { _key: string; _type: string }>;
}
const LogoBanner = ({ header = "", logos, urlFor }: Props) => {
  if (!logos) return null;
  return (
    <div className="w-full flex flex-col items-center justify-center mt-10 mb-20 space-y-10 lg:space-y-0 lg:flex-row">
      <h3 className="text-primary-1 text-2xl font-semibold min-w-fit">
        {header}
      </h3>
      <div className="grid sm:grid-cols-1 gap-10 lg:grid-cols-4">
        {logos?.map((logo) => {
          return (
            <div className="relative w-[217px] h-[60px]" key={logo._key}>
              <Image
                src={urlFor(logo).url()}
                layout="fill"
                objectFit="contain"
                alt="logo"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default LogoBanner;
